/*
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2022-11-09 11:45:49
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2025-01-23 11:06:10
 * @FilePath: \saas_front\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

let routes = [
	{
		path: '/',
		meta: {
			title: '筑云saas平台'
		},
		redirect: {
      name: 'HomePage'
    },
    component: () => import('@/views/webPage/webPage'),
	},
]
const filesWebsite = require.context('@/router/website', true, /\.js$/)
filesWebsite.keys().forEach(key => {
  const name = Object.keys(filesWebsite(key).default)
  name.forEach(element => {
    routes = routes.concat(filesWebsite(key).default[element])
  });
})
const router = new VueRouter({
  mode: "history",
  base: '/',
  routes,
});


// routes = routes.filter((item) => {
//   return !!item
// })

// 查找是否在当前路由列表内
const getRouteObj = (path, list) => {
  if (list.find(ele => ele.path === path)) return true;
  for (let i = 0; i < list.length; i++) {
    if (list[i].path === path) {
      return true;
    }
    if (Array.isArray(list[i]?.children)) {
      if (list[i].children.find(ele => ele.path === path)) return true;
    }
  }
  return false;
} 

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    //如果设置标题，拦截后设置标题
    document.title = to.meta.title;
  }
  if(to.path === 'openFile.html') {
    next()
    return
  }
  // 拦截非法路由
  if (!getRouteObj(to.path, routes)) {
    next({
      path: '/'
    })
    return;
  }
  next();
});
export default router;
